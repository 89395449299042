<template>
  <a-spin :spinning="loading">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center"></div>
      <!-- <div>
        <a-button @click.prevent="" type="danger">
          <LeftSquareOutlined />
          Batal</a-button
        >
      </div> -->
    </div>
    <a-card>
      <a-row class="mt-4" :gutter="[32, 32]">
        <a-col :span="24" class="d-flex justify-content-between align-items-center">
          <a-button @click="$router.back()">
            <LeftOutlined />Back
          </a-button>
          <a-button @click="changeEdittable">
            <span v-if="!editable">
              <FormOutlined class="mr-2" />Edit
            </span>
            <span v-else>
              <EyeOutlined class="mr-2" />View
            </span>
          </a-button>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Date</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :sm="{ span: 6 }">
                <a-date-picker format="DD / MM / YYYY" class=" w-100" v-model:value="dataPayload.date"></a-date-picker>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Troubleshooter Detail</h1>
          </div>
          <a-row :gutter="[24, 12]">
            <a-col :span="24" class="d-flex justify-content-between  align-items-center" style="gap: 15px;">
              <div class="w-100">
                <p style="text-align: left;" class="text-label">Company</p>
              </div>
              <div class="w-100">
                <p style="text-align: left;" class="text-label">Name</p>
              </div>
              <div class="w-100">
                <p style="text-align: left;" class="text-label">Title</p>
              </div>

              <div class="w-100"></div>
            </a-col>
            <a-col :span="24" class="d-flex justify-content-between  align-items-center" style="gap: 15px;"
              v-for="(item, index) in troubleshootDetailData" :key="index">
              <div class="w-100">
                <a-select class="w-100" placeholder="Pilih Company" v-model:value="item.vendorId">
                  <a-select-option v-for="(itemV, indexV) in dataVendors" :key="indexV" :value="itemV.id"
                    @click="handleVendor(item, itemV)">{{ itemV.name
                    }}</a-select-option>
                </a-select>
              </div>
              <div class="w-100">
                <a-input v-model:value="item.name" placeholder="Masukkan Nama" disabled></a-input>
              </div>
              <div class="w-100">
                <a-input v-model:value="item.title" placeholder="Masukkan Title" disabled></a-input>
              </div>

              <div class="w-100">
                <a-button @click="deleteTroubleshootDetail(item._id)" type="danger">
                  <DeleteOutlined />
                </a-button>
              </div>
            </a-col>
            <a-col :span="24">
              <a-button @click="addTroubleshootDetail">
                <PlusOutlined />
              </a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Troubleshoot Requester</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 24 }" :sm="{ span: 6 }">
                <p class="text-label">Nama</p>
                <a-select class="w-100 " placeholder="Pilih User" v-model:value="selectedSelectionRequester"
                  @change="handleChangeSelectedRequester">
                  <a-select-option v-for="(item, index) in dataSelectionRequester" :key="index" :value="item.id">{{
    item.username }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 6 }">
                <p class="text-label">Title</p>
                <a-input v-model:value="selectedDataRequester.role" class=" " disabled
                  placeholder="Masukkan Title"></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 6 }">
                <p class="text-label">Email</p>
                <a-input v-model:value="selectedDataRequester.email" class=" " disabled
                  placeholder="Masukkan Email"></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 6 }">
                <p class="text-label">Nomor Telepon</p>
                <a-input v-model:value="selectedDataRequester.phoneNumber" class=" " disabled
                  placeholder="Masukkan Nomor Telepon"></a-input>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Aset Detail</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :span="24">
                <h2 class="text-subtitle">Aset Information</h2>
                <a-divider></a-divider>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Asset Category</p>
                  <a-select placeholder="Pilih Asset Category" class="w-100" v-model:value="selectedCategories"
                    @change="handleChangeCategories" disabled>
                    <a-select-option v-for="(item, index) in dataAssetCategories" :key="index" :value="item.id">{{
    item.name }}</a-select-option>
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Asset Sub Category</p>
                  <a-select placeholder="Pilih Asset Sub Category" class="w-100" v-model:value="selectedSubCategories"
                    @change="handleChangeSubCategories" disabled>
                    <a-select-option v-for="(item, index) in dataAssetSubCategories" :key="index" :value="item.id">{{
    item.name }}</a-select-option>
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="selectedRequestType != 'Addition'">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Asset</p>
                  <a-select placeholder="Pilih Asset" class="w-100" v-model:value="selectedAsset"
                    @change="handelChangeAssets" disabled>
                    <a-select-option v-for="(item, index) in dataAssets" :key="index" :value="item.id">{{ item.name
                      }}</a-select-option>
                  </a-select>
                </div>
              </a-col>
              <!--  -->
              <!--  -->
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="selectedRequestType != 'Addition'">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Brand</p>
                  <a-select placeholder="Pilih Manufacturer" class="w-100"
                    v-model:value="dataSelectedAsset.manufacturerId" disabled>
                    <a-select-option v-for="(item, index) in dataManufatures" :key="index" :value="item.id">{{ item.name
                      }}</a-select-option>
                  </a-select>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="selectedRequestType != 'Addition'">
                <p style="text-align: left;" class="text-label">Model</p>
                <a-input placeholder="Masukkan Model" v-model:value="dataSelectedAsset.modelAsset" disabled></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="selectedRequestType != 'Addition'">
                <p style="text-align: left;" class="text-label">Product No</p>
                <a-input placeholder="Masukkan Product No" v-model:value="dataSelectedAsset.productNumber"
                  disabled></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="selectedRequestType != 'Addition'">
                <p style="text-align: left;" class="text-label">Serial No</p>
                <a-input placeholder="Masukkan Serial No" v-model:value="dataSelectedAsset.serialNumber"
                  disabled></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="selectedRequestType != 'Addition'">
                <p style="text-align: left;" class="text-label">Service Tag</p>
                <a-input placeholder="Masukkan Service Tag" v-model:value="dataSelectedAsset.serviceTag"
                  disabled></a-input>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }" v-if="selectedRequestType != 'Addition'">
                <p style="text-align: left;" class="text-label">Asset Tag</p>
                <a-input placeholder="Masukkan Asset Tag" v-model:value="dataSelectedAsset.assetTag" disabled></a-input>
              </a-col>
            </a-row>
            <a-row :gutter="[24, 12]" class="mt-5">
              <a-col :span="24">
                <h2 class="text-subtitle">Procurement Information</h2>
                <a-divider></a-divider>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Tanggal Pembelian</p>
                <!-- <a-input  placeholder="Masukkan System Name"></a-input> -->
                <a-date-picker v-model:value="dataPayload.procurements.purchaseDate" format="DD / MM / YYYY"
                  class=" w-100" disabled></a-date-picker>
              </a-col>
              <a-col :span="12">
                <p class="text-label">Nomor Pembelian</p>
                <a-input placeholder="Masukkan Nomor Pembelian" v-model:value="dataPayload.procurements.No"
                  disabled></a-input>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Garansi</p>
                <a-input placeholder="Masukkan Garansi" suffix="Month(s)"
                  v-model:value="dataPayload.procurements.warranty" disabled></a-input>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Garansi Mulai</p>
                <!-- <a-input  placeholder="Masukkan DNS"></a-input> -->
                <a-date-picker format="DD / MM / YYYY" class=" w-100"
                  v-model:value="dataPayload.procurements.warrantyStart" disabled></a-date-picker>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Garansi Berakhir</p>
                <!-- <a-input  placeholder="Masukkan IP Address"></a-input> -->
                <a-date-picker format="DD / MM / YYYY" class=" w-100"
                  v-model:value="dataPayload.procurements.warrantyEnd" disabled></a-date-picker>
              </a-col>
              <a-col :span="12">
                <p class="text-label">Vendor</p>
                <!-- <a-input  placeholder="Masukkan OS / Nomor Serial"></a-input> -->
                <a-select placeholder="Pilih Vendor" class="w-100" v-model:value="dataPayload.procurements.vendorId"
                  @change="handleChangeProcurementVendor" disabled>
                  <a-select-option v-for="(item, index) in dataVendors" :key="index" :value="item.id">{{ item.name
                    }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Email</p>
                <a-input disabled placeholder="Masukkan Email" v-model:value="dataPayload.procurements.email"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Nomor Telepon</p>
                <a-input disabled placeholder="Masukkan Nomor Telepon"
                  v-model:value="dataPayload.procurements.mobileNumber"></a-input>
              </a-col>
            </a-row>
            <a-row :gutter="[24, 12]" class="mt-5">
              <a-col :span="24">
                <h2 class="text-subtitle">Maintenance Information</h2>
                <a-divider></a-divider>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Tanggal Kontrak</p>
                <!-- <a-input  placeholder="Masukkan System Name"></a-input> -->
                <a-date-picker format="DD / MM / YYYY" class=" w-100"
                  v-model:value="dataPayload.maintenances.purchaseDate" disabled></a-date-picker>
              </a-col>
              <a-col :span="12">
                <p class="text-label">Nomor Kontrak</p>
                <a-input placeholder="Masukkan Nomor Kontrak" v-model:value="dataPayload.maintenances.No"
                  disabled></a-input>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Garansi</p>
                <a-input placeholder="Masukkan Garansi" suffix="Month(s)"
                  v-model:value="dataPayload.maintenances.warranty" disabled></a-input>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Garansi Mulai</p>
                <!-- <a-input  placeholder="Masukkan DNS"></a-input> -->
                <a-date-picker format="DD / MM / YYYY" class=" w-100"
                  v-model:value="dataPayload.maintenances.warrantyStart" disabled></a-date-picker>
              </a-col>
              <a-col :sm="{ span: 8 }" :xs="{ span: 12 }">
                <p class="text-label">Garansi Berakhir</p>
                <!-- <a-input  placeholder="Masukkan IP Address"></a-input> -->
                <a-date-picker format="DD / MM / YYYY" class=" w-100"
                  v-model:value="dataPayload.maintenances.warrantyEnd" disabled></a-date-picker>
              </a-col>
              <a-col :span="12">
                <p class="text-label">Vendor</p>
                <!-- <a-input  placeholder="Masukkan OS / Nomor Serial"></a-input> -->
                <a-select placeholder="Pilih Vendor" class="w-100" v-model:value="dataPayload.maintenances.vendorId"
                  @change="handleChangeMaintenancesVendor" disabled>
                  <a-select-option v-for="(item, index) in dataVendors" :key="index" :value="item.id">{{ item.name
                    }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Email</p>
                <a-input disabled placeholder="Masukkan Email" v-model:value="dataPayload.maintenances.email"></a-input>
              </a-col>
              <a-col :xs="{ span: 12 }" :sm="{ span: 8 }">
                <p class="text-label">Nomor Telepon</p>
                <a-input disabled placeholder="Masukkan Nomor Telepon"
                  v-model:value="dataPayload.maintenances.mobileNumber"></a-input>
              </a-col>
            </a-row>
            <a-row :gutter="[24, 12]" class="mt-5" v-if="false">
              <a-col :span="24">
                <h2 class="text-subtitle">Tecnical Information</h2>
                <a-divider></a-divider>
              </a-col>
              <a-col :span="8">
                <p class="text-label">System Name</p>
                <a-input :disabled="!editable" placeholder="Masukkan System Name"></a-input>
              </a-col>
              <a-col :span="8">
                <p class="text-label">Host Name</p>
                <a-input :disabled="!editable" placeholder="Masukkan Host Name"></a-input>
              </a-col>
              <a-col :span="8">
                <p class="text-label">Domain Role</p>
                <a-input :disabled="!editable" placeholder="Masukkan Domain Role"></a-input>
              </a-col>
              <a-col :span="6">
                <p class="text-label">DNS</p>
                <a-input :disabled="!editable" placeholder="Masukkan DNS"></a-input>
              </a-col>
              <a-col :span="6">
                <p class="text-label">IP Address</p>
                <a-input :disabled="!editable" placeholder="Masukkan IP Address"></a-input>
              </a-col>
              <a-col :span="12">
                <p class="text-label">OS / Nomor Serial</p>
                <a-input :disabled="!editable" placeholder="Masukkan OS / Nomor Serial"></a-input>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Post Troubleshoot Remarks</h1>
          </div>
          <a-row :gutter="[24, 12]">
            <a-col :xs="{ span: 24 }" :sm="{ span: 7 }" :span="7">
              <p class="text-label">Type of Failure</p>
              <!-- <a-input placeholder="Masukkan Type of Failure"></a-input> -->
              <a-select placeholder="Pilih Type of Failure" v-model:value="dataPayload.typeOfFailure" class="w-100">
                <!-- <a-select-option :key="1000" :value="null">Pilih</a-select-option> -->
                <a-select-option v-for="(item, index) in dataTypeofFailure" :key="index" :value="item">{{ item
                  }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :xs="{ span: 24 }" :sm="{ span: 7 }" :span="7">
              <p class="text-label">Type of Solving</p>
              <!-- <a-input placeholder="Masukkan Type of Solving"></a-input> -->
              <a-select placeholder="Pilih Type of Solving" v-model:value="dataPayload.typeOfSolving" class="w-100">
                <!-- <a-select-option :key="1000" :value="null">Pilih</a-select-option> -->
                <a-select-option v-for="(item, index) in dataTypeofSolving" :key="index" :value="item">{{ item
                  }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :xs="{ span: 24 }" :sm="{ span: 7 }" :span="7">
              <p class="text-label">Reparation</p>
              <!-- <a-input placeholder="Masukkan Reparation"></a-input> -->
              <a-select placeholder="Pilih Reparation" v-model:value="dataPayload.reparation" class="w-100">
                <!-- <a-select-option :key="1000" :value="null">Pilih</a-select-option> -->
                <a-select-option v-for="(item, index) in dataReparation" :key="index" :value="item">{{ item
                  }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :xs="{ span: 24 }" :sm="{ span: 14 }" :span="14">
              <p class="text-label">Explain</p>
              <a-textarea placeholder="Masukkan Explain" v-model:value="dataPayload.explaination"></a-textarea>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="24">
          <a-row :gutter="[24, 12]">
            <a-col :span="18"></a-col>
            <a-col :span="6" class="d-flex justify-content-end align-items-center" style="gap:15px">
              <a-button @click="$router.back()" type="primary">Cancel</a-button>
              <a-button @click="handleSubmit">Submit</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-card>
  </a-spin>
</template>

<script>
import { onMounted, ref, createVNode, computed, reactive, onBeforeMount } from 'vue'
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  LeftOutlined,
  FormOutlined,
  EyeOutlined,
} from '@ant-design/icons-vue'
import { message, Modal, notification } from 'ant-design-vue'
import { getOne, remove, getAllPendings, update } from '@/services/axios/brivice-backend'
import apiClient from '@/services/axios'
import assetsFile from '@/data/modc_dump.json'
import moment from 'moment'
import { uniqueId } from 'lodash'
import { getAllUser } from '../../services/axios/modc/user'
import { getChangeRequestById, editOrCreateTroubleshoot } from '../../services/axios/modc/helpdesk'
import { getAssetCategory, getAssets, getCategories } from '../../services/axios/modc/assets'
import { getManufactures } from '../../services/axios/modc/manufacture'
import { getVendors } from '../../services/axios/modc/vendor'

export default {
  components: { LeftOutlined, DeleteOutlined, PlusOutlined, FormOutlined, EyeOutlined },
  setup() {
    let store = useStore()
    let route = useRoute()
    let router = useRouter()
    const assets = ref()
    const manufactures = ref()
    const loading = ref(false)
    const catFacility = ref('Access Point')
    const facility = ref('Access-002')
    const selectedTypeofFailure = ref()
    const selectedTypeofSolving = ref()
    const selectedReparation = ref()
    const editable = ref(false)
    const troubleshootDetailData = ref([])
    const dataTypeofFailure = [
      'Hardware Failure',
      'Software Failure',
      'System Failure',
      'Part Failure',
      'Broken Part',
      'Other',
    ]
    const dataTypeofSolving = ['Reparation', 'Part Replacement', 'Reconfiguration']
    const dataReparation = ['Minor', 'Major', 'Overhaul', 'Other']

    // BRAND/MANUFACTURE
    const dataManufatures = ref([])
    const selectedManufatures = ref()

    // VENDORS
    const dataVendors = ref([])
    const selectedVendors = ref()
    const selectedVendors2 = ref()

    const selectedRequestType = ref()

    const dataSelectionRequester = ref([])
    const selectedSelectionRequester = ref()
    const selectedDataRequester = ref({})

    const dataAssetCategories = ref([])
    const selectedCategories = ref()

    const dataAssetSubCategories = ref([])
    const selectedSubCategories = ref()

    const dataAssets = ref([])
    const selectedAsset = ref()

    const dataPayload = ref({
      date: null,
      requestId: null,
      typeOfFailure: null,
      typeOfSolving: null,
      reparation: null,
      explaination: null,
      tbMasterId: null,
      troubleshooter: [],
    })
    const dataSelectedAsset = ref({
      manufacturerId: null,
      modelAsset: null,
      productNumber: null,
      serialNumber: null,
      serviceTag: null,
      assetTag: null,
    })

    onBeforeMount(() => {
      getInitialData()
    })
    // created() {

    // }
    const getInitialData = async () => {
      loading.value = true
      const resUser = await getAllUser()
      dataSelectionRequester.value = resUser.users
      const resChangeRequest = await getChangeRequestById(route.params.id)
      // console.log(resChangeRequest, '<<<<<HALLO')

      const categories = await getCategories()
      const assetCategory = await getAssetCategory()
      const filteredAssetCategory = assetCategory.categories.filter(
        cat => cat.name != 'Software' && cat.name != 'Infrastructure' && cat.name != 'Monitoring',
      )

      // console.log(filteredAssetCategory, '<<<ASET CATEGORY')
      let catAssets

      const manufactures = await getManufactures()
      dataManufatures.value = manufactures.manufacturers

      const {
        AssetSubCategory,
        Customer,
        HardwareInformation,
        Location,
        Manufacturer,
        Room,
        TechnicalActiveDevice,
        TechnicalFacility,
        TechnicalPassiveDevice,
        TechnicalSoftware,
        ProcurementInformation,
        MaintenanceInformation,
        Components,
        ...rest
      } = resChangeRequest.rqDetail.Asset

      const vendors = await getVendors()
      dataVendors.value = vendors.vendor
      // const requestChange = await getChangeRequestById(route.params.id)
      console.log(resChangeRequest, '<<<<<CHANGE')
      selectedRequestType.value = resChangeRequest.rqDetail.type
      await handleChangeRequestType(resChangeRequest.rqDetail.type)
      selectedCategories.value = resChangeRequest.rqDetail.Asset.AssetSubCategory.assetCategoryId
      await handleChangeCategories(resChangeRequest.rqDetail.Asset.AssetSubCategory.assetCategoryId)
      selectedSubCategories.value = resChangeRequest.rqDetail.Asset.AssetSubCategory.id
      await handleChangeSubCategories(resChangeRequest.rqDetail.Asset.AssetSubCategory.id)
      selectedAsset.value = resChangeRequest.rqDetail.Asset.id
      await handelChangeAssets(resChangeRequest.rqDetail.Asset.id)

      dataPayload.value.tbMasterId = resChangeRequest.rqDetail.TroubleshootMasters.length
        ? resChangeRequest.rqDetail.TroubleshootMasters[0].id
        : null
      dataPayload.value.date = resChangeRequest.rqDetail.TroubleshootMasters.length
        ? moment(resChangeRequest.rqDetail.TroubleshootMasters[0].date)
        : null

      dataPayload.value.typeOfFailure = resChangeRequest.rqDetail.TroubleshootMasters.length
        ? resChangeRequest.rqDetail.TroubleshootMasters[0].PostTroubleshootRemark.typeOfFailure
        : null
      dataPayload.value.typeOfSolving = resChangeRequest.rqDetail.TroubleshootMasters.length
        ? resChangeRequest.rqDetail.TroubleshootMasters[0].PostTroubleshootRemark.typeOfSolving
        : null
      dataPayload.value.explaination = resChangeRequest.rqDetail.TroubleshootMasters.length
        ? resChangeRequest.rqDetail.TroubleshootMasters[0].PostTroubleshootRemark.explaination
        : null
      dataPayload.value.reparation = resChangeRequest.rqDetail.TroubleshootMasters.length
        ? resChangeRequest.rqDetail.TroubleshootMasters[0].PostTroubleshootRemark.reparation
        : null
      troubleshootDetailData.value = resChangeRequest.rqDetail.TroubleshootMasters.length
        ? resChangeRequest.rqDetail.TroubleshootMasters[0].TroubleshooterDetails
        : []
      // dataPayload.value.tbMasterId =
      dataPayload.value = {
        ...dataPayload.value,
        customerId: String(rest.customerId),
        locationId: String(rest.locationId),
        roomId: String(rest.roomId),
        assetSubCategoryId: String(AssetSubCategory.id),
        name: rest.name,
        manufacturerId: String(rest.manufactureId),
        modelAsset: rest.model,
        productNumber: rest.productNumber,
        serialNumber: rest.serialNumber,
        serviceTag: rest.serviceTag,
        assetTag: rest.assetTag,
        rackNumber: rest.rackNumber,
        uNumber: rest.uNumber,
        uSize: rest.uSize,
        procurements: {
          ...ProcurementInformation,
        },
        maintenances: {
          ...MaintenanceInformation,
        },
        ...TechnicalActiveDevice,
        ...TechnicalFacility,
        ...TechnicalPassiveDevice,
        ...TechnicalSoftware,
        ...Components,
        cableManagement: TechnicalPassiveDevice?.cableManagement,
        crossConnection: TechnicalPassiveDevice?.crossConnection,
      }
      loading.value = false
    }
    const getCurrentDate = computed(() => {
      return moment()
    })
    const handleChangeRequestType = async e => {
      const assetCategory = await getAssetCategory()
      const filteredAssetCategory = assetCategory.categories.filter(
        cat => cat.name != 'Software' && cat.name != 'Infrastructure' && cat.name != 'Monitoring',
      )
      // const lastAssetCategory = filteredAssetCategory.filter(cat => {
      //   if (e == 'Troubleshooting') {
      //     if (cat.name != 'Passive Devices') return cat
      //   } else return cat
      // })
      dataAssetCategories.value = filteredAssetCategory
      dataPayload.value.type = e
      // dataAssetSubCategories.value = lastAssetCategory
    }
    const handleChangeCategories = async e => {
      const subCategories = dataAssetCategories.value.find(cat => cat.id == e)
      console.log(
        dataAssetCategories.value.find(cat => cat.id == e),
        '<<<<HALO',
      )
      dataAssetSubCategories.value = subCategories.AssetSubCategories
    }
    const handleChangeSubCategories = async e => {
      const assets = await getAssets({ type: e })
      dataAssets.value = assets.assets
    }

    const handelChangeAssets = async e => {
      const assets = dataAssets.value.find(asset => asset.id == e)
      dataPayload.value.assetId = assets.id
      dataSelectedAsset.value = assets
      console.log(assets, '<<<ASSETs')
    }

    const addTroubleshootDetail = () => {
      troubleshootDetailData.value.push({
        _id: uniqueId('tbs-'),
        name: '',
        title: '',
        vendorId: null,
      })
    }
    const deleteTroubleshootDetail = id => {
      troubleshootDetailData.value = troubleshootDetailData.value.filter(trb => trb._id !== id)
    }

    const changeEdittable = () => {
      editable.value = !editable.value
    }

    const handleChangeSelectedRequester = e => {
      if (e) {
        selectedDataRequester.value = dataSelectionRequester.value.find(data => data.id == e)
      }
    }

    const handleSubmit = async () => {
      dataPayload.value.requestId = Number(route.params.id)
      // dataPayload.value.date = dataPayload.value.date ? dataPayload.value.date.toDate() : null
      dataPayload.value.troubleshooter = troubleshootDetailData.value
      const payload = dataPayload.value
      const res = await editOrCreateTroubleshoot(payload)
      console.log(res, '<<<<<PAYLOAD')
      if (res) {
        notification.success({
          message: 'Berhasil Submit',
          description: 'Data Troubleshoot Berhasil diSubmit',
        })
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Internal Server Error',
        })
      }
      // setTimeout(() => {
      router.push({ name: 'Troubleshoot' })
      // }, 1000)
    }
    const handleVendor = (item, itemV) => {
      item.name = itemV.PIC
      item.title = 'PIC'
    }
    return {
      handleChangeCategories,
      handleVendor,
      // detail troubleshoot
      facility,
      catFacility,
      troubleshootDetailData,
      // Last
      dataTypeofFailure,
      dataTypeofSolving,
      dataReparation,
      selectedReparation,
      selectedTypeofFailure,
      selectedTypeofSolving,
      editable,
      loading,

      getCurrentDate,
      changeEdittable,

      addTroubleshootDetail,
      deleteTroubleshootDetail,
      handleSubmit,
      // dataSource,
      //
      dataSelectionRequester,
      selectedSelectionRequester,
      selectedDataRequester,
      selectedRequestType,
      handleChangeSelectedRequester,

      // CATEGORY ASSETS
      // dataRequestType,
      dataAssetCategories,
      selectedCategories,
      dataAssetSubCategories,
      selectedSubCategories,
      dataAssets,
      selectedAsset,
      // CATEGORY ASSETS

      //
      dataSelectedAsset,
      dataManufatures,
      selectedManufatures,
      dataVendors,
      selectedVendors,
      selectedVendors2,
      dataPayload,
    }
  },
}
</script>
<style lang="scss">
@import '../styleViews.scss';

.text-title {
  font-size: 1.5rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-subtitle {
  font-size: 1.3rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-label {
  font-size: 1rem;
  color: $text;
  font-weight: 400;
  margin-bottom: 10px;
}

// . {
//   background-color: white !important;
//   input {
//     background-color: white !important;
//   }
// }
// :global(.ant-select-:disabled="!editable".ant-select:not(.ant-select-customize-input)) {
//   :global(.ant-select-selector) {
//     background-color: white !important;
//   }
// }
// .ant-input .ant- {
//   background-color: #ffffff !important;
// }
.th-upload-wrapper {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;

    .ant-upload {
      width: 300px;
      height: 100px;
    }
  }
}
</style>
